import { useEffect, useState } from "react";
import CategoriesItem from "../components/categoriesItem/CategoriesItem";
import Path from "../components/path/Path";
import { RightLow } from "../images/arrows";
import useMainService from "../services/MainService";
import CategoriesList from "../components/categoriesList/CategoriesList";
import { useTranslation } from "react-i18next";

const Categories = () => {
    const pathValues = ["Главная", "Категории"];
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState([]);
    const { getAllCategories } = useMainService();
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        getAllCategories()
            .then(onCategoriesLoad)
    }, []);

    const onCategoriesLoad = (categories) => {
        setLoading(false);
        setCategories(categories);
    }

    return (
        <div className="main">
            <CategoriesList />
            
            <Path values={pathValues} icon={RightLow} />
            <div className="main__container">
                <div className="title">
                    {t("categories")}
                </div>

                <section className="categories grid">
                    {categories.map((item, index) => (
                        <CategoriesItem
                            key={index}
                            name={item.name}
                            to={`/catalog?categoryname=${item.name}`}
                            icon={item.id - 1} />
                    ))}
                </section>
            </div>
        </div>
    )
}

export default Categories;