import {useEffect, useMemo, useState} from "react";
import BoughtCard from "../boughtCard/BoughtCard";
import {Pubg} from "../../images/backgrounds";
import useMainService from "../../services/MainService";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import EmptyPage from "../emptyPage/EmptyPage";
import { RotatingLines } from "react-loader-spinner";

const Tags = (props) => {
    const {desc, id, instruction} = props;
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const {getAllProductsById, getProductById} = useMainService();
    const {t} = useTranslation();

    // Получаем данные из Redux
    const {currencies,activeCurrency} = useSelector(state => state.cart);

    useEffect(() => {
        if (id) {
            setLoading(true);
            getAllProductsById(id)
                .then(onProductsLoaded)
                .catch(log => console.log(log));
        }
    }, [id]);

    const onProductsLoaded = async (prods) => {
        // Проверяем доступность каждого товара с помощью getProductById
        const availableProducts = [];
        for (const product of prods) {
            const productDetails = await getProductById(product.id);
            if (productDetails.available) {
                availableProducts.push(product);
            }
        }
        setLoading(false);
        setProducts([...availableProducts]);
    };

    const discountGames = [
        "Mobile Legends",
        "PSN Card United States",
        "Xbox Live Gift Card",
        "Steam Wallet Code TL - (Turkey)",
        "Fortnite",
    ];

    // Проверяем, существует ли валюта с текущим индексом
    const currencyExists = activeCurrency && currencies[activeCurrency.index];

    // Если валюта найдена, берем её amount, иначе устанавливаем курс = 1
    const currencyAmount = currencyExists ? currencies[activeCurrency.index].amount : 1;


    useEffect(() => {
        // Добавляем эффект, чтобы реагировать на изменение активной валюты
        console.log("Currency changed:", activeCurrency);
    }, [activeCurrency]);

    const children = useMemo(() => (
        products.map((item, index) => (
            <BoughtCard
                itemId={item.id}
                key={index}
                oldPrice={(item.priceWithDiscount + 0.88 + (discountGames.includes(item.gameName) ? 10 : 0)) * currencyAmount}
                count={0}
                src={item.images ? item.images : Pubg}
                text={item.name}
                price={Math.ceil(item.price * currencyAmount).toLocaleString('ru-RU')}
            />
        ))), [products, currencyAmount]);

    return (
        <div className="tag">
            <div className="tag__header">
                <div className="sort">
                    <div onClick={() => setActive(0)} className={"sort__item " + (active === 0 ? "active" : "")}>
                        <div className="sort__text">
                            {t('buy')}
                        </div>
                    </div>
                    <div onClick={() => setActive(1)} className={"sort__item " + (active === 1 ? "active" : "")}>
                        <div className="sort__text">
                            {t('desc')}
                        </div>
                    </div>
                    <div onClick={() => setActive(2)} className={"sort__item " + (active === 2 ? "active" : "")}>
                        <div className="sort__text">
                            {t('instruction')}
                        </div>
                    </div>
                </div>
            </div>
            {id ? (
                <div className="tag__body">
                    {loading ? (  // Показываем спиннер во время загрузки
                        <div style={{margin:"0 auto"}}>
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />
                        </div>

                    ) : products.length === 0 ? (  // Если загрузка завершена, но товаров нет
                        <EmptyPage mainText={t('no-products')} text={t('please-check-later')} />
                    ) : (
                        <div className={"tag__item " + (active === 0 ? "active" : "")}>
                            <div className="frame">
                                {children}
                            </div>
                        </div>
                    )}
                    <div className={"tag__item " + (active === 1 ? "active" : "")}>
                        <div className="frame">
                            <div className="tag__info">
                                <div className="tag__title">{t("instruction")}</div>
                                <div className="tag__text" dangerouslySetInnerHTML={{__html: desc}}></div>
                            </div>
                        </div>
                    </div>
                    <div className={"tag__item " + (active === 2 ? "active" : "")}>
                        <div className="frame">
                            <div className="tag__info">
                                <div className="tag__title">{t('instruction')}</div>
                                <div className="tag__text" dangerouslySetInnerHTML={{__html: instruction}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Tags;