import NavigationItem from "./NavigationItem";
import Profile from "./Profile";
import { useContext, useMemo } from "react";
import { Cart, CartHover, Like, LikeHover, Notification, NotificationHover } from "../../../images/system";
import AuthModal from "../../authModal/AuthModal";
import { AuthContext } from "../../../providers/AuthProvider";
import { PopupContext } from "../../../providers/PopupProvider";
import { useNavigate } from "react-router-dom";
import LngMenu from "../../lngMenu/LngMenu";
import CurrencyMenu from "../../currencyMenu/CurrencyMenu";

const Navigation = () => {
    const context = useContext(AuthContext);
    const { isShow, setShow } = useContext(PopupContext);
    const navigate = useNavigate();

    const element = useMemo(() => {
        return context.isAuth ? <Profile /> :
            <AuthModal />
    }, [context.isAuth]);

    const number = useMemo(() => (localStorage.getItem('cart_add')));

    const toCart = () => {
        if (context.isAuth) {
            navigate('/cart');
        } else {
            setShow(true);
        }
    }

    const toFavourite = () => {
        if (context.isAuth) {
            navigate('/favourites');
        } else {
            setShow(true);
        }
    }

    return (
        <div className="header__navigation navigation">
            <LngMenu />
            <CurrencyMenu/>
            <NavigationItem onClick={toFavourite} src={Like} srcHover={LikeHover} alt="favourite games" />
            <NavigationItem onClick={toCart} src={Cart} isCart srcHover={CartHover} alt="my cart" />
            <NavigationItem src={Notification} srcHover={NotificationHover} alt="notifications" />
            {element}
        </div>
    )
}

export default Navigation;