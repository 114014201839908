import { useContext} from "react";
import LogoHeader from "./logoHeader/LogoHeader";
import Search from "./search/Search";
import Navigation from "./navigation/Navigation";
import { Guarantee } from "../../images/status";
import NavBottom from "./NavBottom";
import { useLocation, useNavigate } from "react-router-dom";
import useMainService from "../../services/MainService";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { setLocalStorageWithExpiry } from "../../services/setLocalStorageWithExpiry";
import { AuthContext } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";


const Header = () => {
    return (
        <>
            <View />
            <NavBottom />
        </>
    );
}

const View = () => {

    const { tokenWithVk } = useMainService();
    const { isAuth, setIsAuth } = useContext(AuthContext);

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const {t} = useTranslation();

    useEffect(() => {
        if (code) {
            tokenWithVk(code)
                .then(data => {
                    console.log("data vk", data);
                    const expiryDate = DateTime.fromISO(data.expired, { zone: 'utc' }).setZone('local');
                    setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
                    setIsAuth(true);
                    navigate("/");
                })
                .catch(e => console.log(e));
        }
    }, []);

    return (
        <header className="header">
            <div className="header__container">
                <LogoHeader />

                <div className="header__row">
                    <Search extra={"header__search"} />
                    <div className="header__icon">
                        <img src={Guarantee} />
                        <p>{t('guarantee1')}<br />{t('guarantee2')}</p>
                    </div>
                </div>
                <Navigation />
            </div>
        </header>
    )
}

export default Header;