import NavBottomItem from "./NavBottomItem";
import { HomeIcon, HomeIconHover } from "../../images/category";
import { Like, LikeHover, Cart, CartHover, Notification, NotificationHover, Lines, LinesHover} from "../../images/system";
import {useTranslation} from "react-i18next";
import {ArcadeGreen, ArcadeIcon} from "../../images/category/navbar";


const NavBottom = () => {
    const { t } = useTranslation();
    return (
        <section className="nav-bottom">
            <div className="nav-bottom__row">
                <NavBottomItem to={"/"} src={HomeIcon} srcHover={HomeIconHover} alt={"home"} text={t("main-link")} />
                <NavBottomItem to={"/favourites"} src={Like} srcHover={LikeHover} alt={"favourites link"} text={t("favourites")} />
                <NavBottomItem isCart to={"/cart"} src={Cart} srcHover={CartHover} alt={"cart link"} text={t("cart")} />
                <NavBottomItem to={"/categories"} src={ArcadeIcon} srcHover={ArcadeIcon} alt={"categories"} text={t("categories-link")} />
                <NavBottomItem to={"/menu"} src={Lines} srcHover={LinesHover} alt={"menu link"} text={t("menu")} />
            </div>
        </section>
    );
}

export default NavBottom;