import Plus from "../../images/status/plus.svg";
import Decrease from "../../images/status/decrease.svg";
import {useContext, useEffect, useState} from "react";
import Button from "../button/Button";
import {Cart, RefreshBlue} from "../../images/system";
import {AuthContext} from "../../providers/AuthProvider";
import useMainService from "../../services/MainService";
import {useBasketService} from "../../services/BasketService";
import {Pubg} from "../../images/backgrounds";
import {useTranslation} from "react-i18next";
import {useCart} from "../../providers/CartProvider";
import {useDispatch} from "react-redux";
import {addItem, updateItemCount} from "../../features/cart/cartSlice";
import {useSelector} from "react-redux";
import NotificationItem from "../notificationItem/NotificationItem";


const CartItem = (props) => {
    const {items, setItems} = useCart();
    const [count, setCount] = useState(props.startCount || 1);
    const [seconds, setSeconds] = useState(5);
    const [product, setProduct] = useState(null);
    const {productId} = props;
    const {isAuth} = useContext(AuthContext);
    const {getProductById} = useMainService();
    const {addItemToBasket, removeItemToBasket, checkBasket} = useBasketService();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [timerActive, setTimerActive] = useState(false);

    // Получаем данные из Redux
    const {currencies,activeCurrency} = useSelector(state => state.cart);

    // Проверяем, существует ли валюта с текущим индексом
    const currencyExists = activeCurrency && currencies[activeCurrency.index];

    // Если валюта найдена, берем её amount, иначе устанавливаем курс = 1
    const currencyAmount = currencyExists ? currencies[activeCurrency.index].amount : 1;

    // Проверяем, существует ли выбранная валюта в Redux и получаем её курс
    const currencySymbol = activeCurrency ? activeCurrency.symbol : '₸';
    console.log("Currency amount -> ",activeCurrency.amount);

    useEffect(() => {
        getProductById(productId)
            .then(data => setProduct(data))
            .catch(err => console.error(err));
    }, [productId]);

    // Добавляем useEffect для перерисовки компонента при изменении валюты
    useEffect(() => {
        console.log("Currency changed, updating prices:", activeCurrency);
    }, [activeCurrency]);

    const updateBasket = async (basketId) => {
        const updatedBasket = await checkBasket(basketId);
        if (updatedBasket && Array.isArray(updatedBasket.basketItems)) {
            setItems(updatedBasket.basketItems);
        }
    };

    const handleChangeCount = async (newCount) => {
        setCount(newCount);

        const basketId = localStorage.getItem('basketId');
        if (basketId && newCount !== 0) {
            const currentItem = items.find(item => item.productId === productId);
            const currentCount = currentItem ? currentItem.count : 0;
            const difference = newCount - currentCount;

            try {
                if (difference > 0) {
                    await addItemToBasket(basketId, productId, difference);
                } else {
                    await removeItemToBasket(basketId, productId, Math.abs(difference));
                }
                updateBasket(basketId);
            } catch (error) {
                console.error("Error updating item quantity on server:", error);
            }
        }

        // Обновляем количество товара в Redux
        dispatch(updateItemCount({productId, count: newCount}));
        console.log('Updated Redux count:', newCount);

        if (newCount === 0) {
            setSeconds(5);
            startDeleteTimer();
        }
    };

    const startDeleteTimer = () => {
        setTimerActive(true);
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 1) {
                    clearInterval(timer);
                    setTimerActive(false);
                    deleteItem();
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    const deleteItem = async () => {
        const basketId = localStorage.getItem('basketId');
        if (basketId) {
            try {
                // Находим товар в корзине и получаем его количество
                const currentItem = items.find(item => item.productId === productId);
                const currentCount = currentItem ? currentItem.count : 0;

                // Удаляем весь товар
                if (currentCount > 0) {
                    await removeItemToBasket(basketId, productId, currentCount);
                    updateBasket(basketId);
                }
            } catch (error) {
                console.error(`Error removing product ${productId}:`, error);
            }
        }
        // Удаление товара в Redux
        dispatch(updateItemCount({ productId, count: 0 }));
    };

    const removeItem = () => {
        if (count > 0) {
            setCount(0);
            startDeleteTimer();
        }
    };

    const restoreItem = async () => {
        if (seconds > 0) {  // только если таймер еще не истек
            setCount(1);
            const basketId = localStorage.getItem('basketId');
            if (basketId) {
                await addItemToBasket(basketId, productId, 1);
                updateBasket(basketId);
            }
            // Восстановление товара в Redux
            dispatch(addItem({productId, count: 1, price: product?.price}));
        }
    };

    useEffect(() => {
        if (count > 0) {
            dispatch(updateItemCount({productId, count}));
        }
    }, [count, dispatch, productId]);

    const button = count === 0 ?
        <Button text={t("add")} src={Cart} extra={"btn-strong"} onClick={() => {
            if (isAuth) handleChangeCount(1)
        }}/> :
        (<div className="counter">
            <div onClick={() => handleChangeCount(count > 0 ? count - 1 : 0)} className="counter__icon">
                <img src={Decrease} alt="decrease count"/>
            </div>
            <div className="counter__text">{count}</div>
            <div onClick={() => handleChangeCount(count + 1)} className="counter__icon plus">
                <img src={Plus} alt="increase count"/>
            </div>
        </div>);

    const price = product && !isNaN(product.price) ? Math.ceil(product.price * currencyAmount) : "Цена не доступна";
    const priceWithDiscount = product?.priceWithDiscount && !isNaN(product.priceWithDiscount)
        ? Math.floor(product.priceWithDiscount * currencyAmount).toLocaleString('ru-RU')
        : null;

    return (
        <div className={"bought-card" + (count === 0 ? " deleted" : "")}>
            <div className="bought-card__img">
                <img src={product?.images || Pubg} alt=""/>
            </div>

            <div className="bought-card__content">
                <div className="bought-card__text">{product?.name || ""}</div>
                <section className="path">
                    <div className="path__row">
                        {priceWithDiscount && priceWithDiscount !== price && (
                            <div className="bought-card__text old">
                                {priceWithDiscount}
                            </div>
                        )}
                        <div
                            className="bought-card__text">{price.toLocaleString('ru-RU')}</div>
                        <div className="path__link">{currencySymbol}</div>
                    </div>
                </section>
            </div>

            <div className="bought-card__btn">
                {button}
            </div>

            <div>
                <NotificationItem removeItem={removeItem}/>
            </div>


            {timerActive && count === 0 && seconds > 0 &&(
                <div className="bought-card__deleted" onClick={restoreItem}>
                    <p>{t('cancel')} {seconds}..</p>
                    <img src={RefreshBlue} alt="cancel deletion"/>
                </div>
            )}
        </div>
    );
}

export default CartItem;
